var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-2",attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":_vm.flowRun.state,"data-cy":'details-tile-flow-run-state|' + _vm.flowRun.state.toLowerCase(),"height":5,"absolute":""}}),(_vm.hasParameters || _vm.hasContext)?_c('CardTitle',{attrs:{"icon":"pi-flow-run"}},[_c('v-row',{staticClass:"d-flex align-center",attrs:{"slot":"title","no-gutters":""},slot:"title"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-truncate pb-1"},[_vm._v(" "+_vm._s(_vm.flowRun.name)+" ")])])],1),_c('div',{staticClass:"d-flex flex-column align-end",attrs:{"slot":"action"},slot:"action"},[_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'overview'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'overview' ? 'primary' : ''},on:{"click":function($event){_vm.tab = 'overview'}}},[_vm._v(" Overview "),_c('v-icon',{attrs:{"small":""}},[_vm._v("calendar_view_day")])],1),(_vm.hasParameters)?_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'parameters'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'parameters' ? 'primary' : ''},on:{"click":function($event){_vm.tab = 'parameters'}}},[_vm._v(" Parameters "),_c('v-icon',{attrs:{"small":""}},[_vm._v("notes")])],1):_vm._e(),(_vm.hasContext)?_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'context'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'context' ? 'primary' : ''},on:{"click":function($event){_vm.tab = 'context'}}},[_vm._v(" Context "),_c('v-icon',{attrs:{"small":""}},[_vm._v("list")])],1):_vm._e()],1)],1):_c('CardTitle',{attrs:{"title":_vm.flowRun.name,"icon":"pi-flow-run"}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.tab === 'overview')?_c('v-list',[(_vm.isCloudOrAutoScheduled)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" Created by ")]),_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.flowRun.auto_scheduled ? 'The Prefect Scheduler' : _vm.flowRun.created_by ? _vm.flowRun.created_by.username : 'A nonexistant user')+" ")])],1)],1):_vm._e(),(_vm.flowRun.state_message)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" Last State Message ")]),_c('truncate',{attrs:{"content":_vm.flowRun.state_message}},[_vm._v(" ["+_vm._s(_vm.formatTime(_vm.flowRun.state_timestamp))+"]: "+_vm._s(_vm.flowRun.state_message)+" ")])],1)],1):_vm._e(),(_vm.flowRun.agent_id)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" Agent ID ")]),_c('router-link',{staticClass:"link",attrs:{"to":{
                name: 'agent',
                params: { tenant: _vm.tenant.slug, id: _vm.flowRun.agent_id }
              }}},[_vm._v(" "+_vm._s(_vm.flowRun.agent_id)+" ")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.flowRun.flow.name)+" version ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'flow', params: { id: _vm.flowRun.flow.id } }}},[_vm._v(" "+_vm._s(_vm.flowRun.flow.version)+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.flowRun.start_time)?_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Scheduled Start Time ")]):_vm._e(),(_vm.flowRun.start_time)?_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatTime(_vm.flowRun.scheduled_start_time))+" ")])]}}],null,false,2988599433)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.flowRun.scheduled_start_time))+" ")])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.flowRun.start_time ? 'Started' : 'Scheduled to start')+" ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.flowRun.start_time ? _vm.formatTime(_vm.flowRun.start_time) : _vm.formatTime(_vm.flowRun.scheduled_start_time))+" ")])]}}],null,false,2403842203)},[_c('div',[_vm._v(" "+_vm._s(_vm.flowRun.start_time ? _vm.formatDateTime(_vm.flowRun.start_time) : _vm.formatDateTime(_vm.flowRun.scheduled_start_time))+" ")])])],1)],1),(_vm.flowRun.end_time)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Ended ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatTime(_vm.flowRun.end_time))+" ")])]}}],null,false,3074124152)},[_c('div',[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.flowRun.end_time))+" ")])])],1)],1):_vm._e(),(_vm.flowRun.start_time)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Duration ")]),_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[(_vm.flowRun.start_time)?_c('DurationSpan',{attrs:{"start-time":_vm.flowRun.start_time,"end-time":_vm.isFinished && _vm.flowRun.start_time
                        ? _vm.flowRun.end_time
                        : null}}):_c('span',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1)],1):_vm._e()],1)],1)],1),_c('LabelEdit',{attrs:{"type":"flowRun","flow-run":_vm.flowRun}})],1):_vm._e()],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.tab === 'parameters')?_c('div',{staticClass:"text-body-2 appForeground rounded-sm pa-5 code-block"},[_vm._v(" "+_vm._s(_vm.formatJson(_vm.flowRunParams))+" ")]):_vm._e()]),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.tab === 'context')?_c('div',{staticClass:"text-body-2 appForeground rounded-sm pa-5 code-block"},[_vm._v(" "+_vm._s(_vm.formatJson(_vm.flowRun.context))+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }